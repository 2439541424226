import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The story of Christ staying behind unannounced as a twelve-year-old boy after
His family returned home from the feast of the Passover has always troubled me.
Upon first reading, the story feels so out of line with the Master's character.
Why did His devotion to His Father's business have to come at the expense of
what I can only imagine were three days of worry, fear, and agony for His
parents as they searched for Him? And when they did find Him, to be met with
rebuke, "And he said unto them, How is it that ye sought me? wist ye not that I
must be about my Father’s business?" You can read the story in
`}<a parentName="p" {...{
        "href": "https://www.lds.org/scriptures/nt/luke/2.40-51?lang=eng#p39"
      }}>{`Luke 2:40–52`}</a>{`.`}</p>
    <p>{`I hesitate to write anything about this because it nearly feels like too sacred
ground to speak about His family relationships or to speculate about His
intentions in such matters. I know the Lord will forgive me if I write in error.`}</p>
    <p>{`It seems to me that the Lord knew very well of His divine calling. And had to
spend a great deal of energy helping those around Him understand it. We know of
one other such occasion, nearly two decades later, where His mother Mary and His
brothers try to get Him to attend to some sort of family matter while He is
teaching. Again He answers almost uncharacteristically, "My mother and my
brethren are these which hear the word of God, and do it". You can read the
story in
`}<a parentName="p" {...{
        "href": "https://www.lds.org/scriptures/nt/luke/8.19-21?lang=eng#p18"
      }}>{`Luke 8:19-21`}</a>{`.`}</p>
    <h2>{`Divine Identity`}</h2>
    <p>{`The Lord knew who He was and what He came to do but it seems those who knew of
His humble origins had the hardest time getting on and understanding His role.
When He does reveal His identity as the promised Messiah, He is met with
criticism,
`}<a parentName="p" {...{
        "href": "https://www.lds.org/study/scriptures/nt/luke/4.17-22?lang=eng#p17"
      }}>{`"Is not this Joseph's son?"`}</a>{`
I believe Mary knew her baby boy was the promised King, after all before her
miraculous conception the angel Gabriel gave her precisely
`}<a parentName="p" {...{
        "href": "https://www.lds.org/study/scriptures/nt/luke/1.32-33?lang=eng"
      }}>{`this revelation`}</a>{`.`}</p>
    <p>{`I sometimes wonder though, exactly how much did she know? Did she know He would
be beaten badly by the Romans, the crown of thorns? the vinegar when He cried
for water? As she watched Him working with wood and nails as a carpenter, did
she know nails would pierce her baby boy's hands and feet to wood? Did she know
He would suffer temptations & pain unlike anyone before or after Him? That when
His body lay limp on a cross, soldiers would gamble for His things in front of
her and His closest friends? When she kissed him did she know that her precious
Son's life would be betrayed by a kiss and bargained for silver? Could any
mother know these things and go on mothering her child without a constant
undercurrent of fear and protection that is only natural to that role?`}</p>
    <p>{`Perhaps she did know. I think she must have known at least a little, I pray she
did not know it all. I can't imagine such knowledge, of the imminent suffering
of your most precious loved one, not robbing the very precious time you have
with them. It seems that in these verses where we learn of her searching for Him
and reprimanding Him as He is about His Father's work or asking Him to come out
while He preached that she did not `}<em parentName="p">{`fully`}</em>{` know. Or at least the numbing effect
of daily concerns kept those realities somewhat at a distance for her. I think
He needed to teach her and perhaps prepare her, just like He needed to teach all
of us, who He is and whose He is.`}</p>
    <p>{`I think Jesus, as a twelve-year-old boy, knew who He was better than anybody. I
think He began the process of letting the world, and His mother know.
Furthermore, I wonder if He knew of the pain His mother would experience as she
surrendered Him to His calling. I wonder if He was preparing her for the
crucifixion. If He, knowing what awaited Him, began preparing His mother to face
that reality. That losing him for three days to unexpected ministry at the
temple as a twelve-year-old was a foreshadowing and a preparation for losing Him
for three days to death itself. Preparation for losing Him day after day as He
ministered and devoted His life to the Father... Not only for her but each of
us. He knew He was the Father's, that His will must be the perfect expression of
the Father's will. I can't help but wonder if His unannounced departure to teach
at the temple in His boyhood was deliberately unannounced to help prepare His
parents for the other, unthinkable things to come. The Savior's family must have
had to learn that He was not only theirs but all of ours. This thought deepens
my admiration and respect for Mary that she also gave her Son for us and that in
order to do so the Lord Himself helped prepare her to do it. Though I'm certain
it was not without pain for Him or for her.`}</p>
    <h2>{`On People Pleasing`}</h2>
    <p>{`One last thought about these stories, I think the Lord is teaching us something
about boundaries here. Isn't it remarkable that He would not let the
expectations of even His closest loved ones stand in the way of fulfilling His
mission? I think the Lord was also teaching us that we must be equally devoted
to fulfilling the measure of our own creation. And when our friends and our
families, in their love, unknowingly risk putting us off course... because
perhaps they don't understand what it is we know we've been called to do... that
we must not let it change our course of action. We can't let the fears of others
feed our own doubts when we are about the Father's business. We can show
compassion and love to the needs of others but that does not mean acting against
how we know we ought to act, indeed
`}<a parentName="p" {...{
        "href": "/kindness-untempered-is-cruelty.md"
      }}>{`kindness untempered is cruelty`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      